import React, { useRef, useState } from 'react'
import { constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import UsDeclarationsKeys from '../../../constants/locale/key/UsDeclarations'
import UsDeclarationsApiUrls from '../../../apiUrls/UsDeclarationsApiUrls'
import pathMap from '../../../paths/pathMap'
import Table from '../../../components/aciacehighway/Table'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import moment from 'moment'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'

const {
  filter: { BETWEEN, LIKE,EQUAL }
} = constants

function TablePage(props) {
  const { history, showNotification } = props

  const tableRef = useRef(null)
  const [alertDialog, setAlertDialog] = useState({ open: false, declarations: null })
  const theme = useTheme()
  const { deleteRecord } = useServices()
  const { translate } = useTranslation([Namespace.UI_COMPONENT, Namespace.US_DECLARATIONS])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const userId = translate(
      Namespace.US_DECLARATIONS,
      UsDeclarationsKeys.USER_ID
    )
    const declarationId = translate(
      Namespace.US_DECLARATIONS,
      UsDeclarationsKeys.DECLARATION_ID
    )
    const declarationDescription = translate(
      Namespace.US_DECLARATIONS,
      UsDeclarationsKeys.DECLARATION_DESCRIPTION
    )
    const createdDate = translate(
      Namespace.US_DECLARATIONS,
      UsDeclarationsKeys.CREATED_DATE
    )
    const updatedDate = translate(
      Namespace.US_DECLARATIONS,
      UsDeclarationsKeys.UPDATED_DATE
    )

    return {
      userId,
      declarationId,
      declarationDescription,
      createdDate,
      updatedDate
    }
  }

  const columns = [
    {
      field: 'declarationId',
      title: translatedTextsObject.declarationId
    },
    {
      field: 'declarationDescription',
      title: translatedTextsObject.declarationDescription
    },
    {
      field: 'userId',
      title: translatedTextsObject.userId
    },
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (rowData) =>
        rowData.createdDate && moment(rowData.createdDate).format('D MMM YYYY')
    },
    {
      field: 'updatedDate',
      title: translatedTextsObject.updatedDate,
      render: (rowData) =>
        rowData.updatedDate && moment(rowData.updatedDate).format('D MMM YYYY')
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.declarationId,
      type: 'textfield',
      name: 'declarationId',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.declarationDescription,
      type: 'textfield',
      name: 'declarationDescription',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.userId,
      type: 'textfield',
      name: 'userId',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.updatedDate,
      type: 'daterange',
      name: 'updatedDate',
      operatorType: BETWEEN
    },
  ]

  function handleDeleteDeclarations() {
    if (!_.isEmpty(alertDialog.declarations)) {
      deleteRecord.execute(
        UsDeclarationsApiUrls.DELETE,
        alertDialog.declarations,
        () => {
          showNotification('success', 'Delete succeeded')
          setAlertDialog({ open: false, declarations: null })

          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }
        },
        (error) => console.log(error)
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.US_DECLARATIONS_ADD_VIEW)
            },
            label: 'Create declaration'
          }
        ]}
        columns={columns}
        compact
        customRowActions={[
          {
            iconButtonProps: {
              icon: ['fal', 'pen'],
              style: { color: theme.palette.primary.main }
            },
            onClick: (rowData) =>
              history.push(`${pathMap.US_DECLARATIONS}/edit/${rowData.id}`)
          }
        ]}
        exportData={{ url: UsDeclarationsApiUrls.EXPORT }}
        fetch={{ url: UsDeclarationsApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) =>
          history.push(`${pathMap.US_DECLARATIONS}/view/${rowData.id}`)
        }
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: 'Delete',
            onClick: (rowData) =>
              setAlertDialog({ open: true, declarations: rowData })
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={alertDialog.open}
        onClose={() => setAlertDialog({ open: false, declarations: null })}
        onCancel={() => setAlertDialog({ open: false, declarations: null })}
        onConfirm={handleDeleteDeclarations}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
