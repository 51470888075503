import React, { useEffect, useRef, useState } from 'react'
import {
  components,
  DataFlattener,
  useServices,
  useTranslation
} from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../constants/locale/key/NbciUsInvoice'
import NbciUsInvoiceApiUrls from '../../../apiUrls/NbciUsInvoiceApiUrls'
import FormProperties from './FormProperties'
import Grid from '@material-ui/core/Grid'
import pathMap from '../../../paths/pathMap'
import PrintFlagDialog from '../../../views/nbci/usinvoice/PrintFlagDialog'
import { useFormContext } from 'react-hook-form'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function EditPage(props) {
  const {
    history,
    invoiceData,
    // onDiscard,
    onSaveProceedNextStep,
    onProceedNextStep,
    // onSetInvoiceData,
    onSetLoading,
    showNotification,
    id,
    invoiceIsDirty,
    setInvoiceIsDirty
  } = props

  const { updateRecord } = useServices()
  const onSuccessCallback = useRef(() => {})
  const [printFlagDialog, setPrintFlagDialog] = useState({
    open: false,
    manifest: null
  })

  const [printAlertDialog, setPrintAlertDialog] = useState({
    open: false,
    manifest: null
  })

  function prePrint(data) {
    if (invoiceIsDirty) {
      setPrintAlertDialog({ open: true, manifest: data })
    } else {
      setPrintFlagDialog({ open: true, manifest: data })
    }
  }

  function handleSaveInvoice(data) {
    onSetLoading(true)

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    updateRecord.execute(
      NbciUsInvoiceApiUrls.PUT,
      FormProperties.toServerDataFormat(data),
      (response) => {
        showNotification('success', 'Invoice updated.')
        response.invoiceDetails = response.invoiceDetails.map(
          (invoice, index) => ({ ...invoice, _id: index })
        )
        response.invoiceSummary = response.invoiceSummary.map(
          (summary, index) => ({ ...summary, _id: index })
        )
        invoiceData.current = response
        setPrintFlagDialog({ open: true, manifest: invoiceData.current })
      },
      (error) => {
        const { data } = error.response

        if (data && data.errorMessages) {
          showNotification('error', data.errorMessages)
        }
      },
      () => {
        onSetLoading(false)
      }
    )
  }

  return (
    <>
      <CngEditForm
        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        showNotification={showNotification}
        renderBodySection={(labelMap, shouldHideMap) => (
          <FormProperties.Fields
            showNotification={showNotification}
            shouldHideMap={shouldHideMap}
            onSetLoading={onSetLoading}
            setInvoiceIsDirty={setInvoiceIsDirty}
            invoiceData={invoiceData}
          />
        )}
        formikProps={FormProperties.formikProps}
        toClientDataFormat={FormProperties.toClientDataFormat}
        toServerDataFormat={FormProperties.toServerDataFormat}
        fetch={{
          url: NbciUsInvoiceApiUrls.GET,
          onPreSuccess: (serverData) => {
            invoiceData.current = serverData
          }
        }}
        update={{
          url: NbciUsInvoiceApiUrls.PUT,
          onPostSubmitSuccess: (e) => {
            invoiceData.current = e
            onSuccessCallback.current()
          }
        }}
        id={id}
        renderButtonSection={(_, loading) => (
          <CustomButtonSection
            data={invoiceData.current}
            history={history}
            loading={loading}
            // onDiscard={() => setAlertDialog(true)}
            onSaveAndContinue={() => {
              onSuccessCallback.current = () => {
                onSaveProceedNextStep()
                onSetLoading(false)
              }
            }}
            onSetLoading={onSetLoading}
            onSetPrintFlagDialog={(data) => prePrint(data)}
            onSkip={onProceedNextStep}
            showNotification={showNotification}
            setInvoiceIsDirty={setInvoiceIsDirty}
          />
        )}
      />
      <PrintFlagDialog
        openDialog={printFlagDialog}
        onClose={() => setPrintFlagDialog({ open: false, manifest: null })}
        showNotification={showNotification}
      />
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, Save and Print'
        open={printAlertDialog.open}
        onClose={() => setPrintAlertDialog({ open: false, manifest: null })}
        onCancel={() => setPrintAlertDialog({ open: false, manifest: null })}
        onConfirm={() => {
          setPrintAlertDialog({ open: false, manifest: null })
          handleSaveInvoice(printAlertDialog.manifest)
        }}
        title='Alert'
      >
        Changes have not been saved. Do you want to proceed with saving then print?
      </AlertDialog>
    </>
  )
}

export default EditPage

function CustomButtonSection(props) {
  const {
    data,
    history,
    loading,
    // onDiscard,
    onSaveAndContinue,
    onSetLoading,
    onSetPrintFlagDialog,
    onSkip,
    showNotification,
    setInvoiceIsDirty
  } = props

  const [isSubmittingInvoice, setIsSubmittingInvoice] = useState(false)
  const {
    formState: { isSubmitting },
    handleSubmit
  } = useFormContext()
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { reset, getValues } = useFormContext()

  const [alertDialog, setAlertDialog] = useState(false)

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  function makeTranslatedTextsObject() {
    const submitButton = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.SUBMIT_BUTTON
    )

    return { submitButton }
  }

  async function handleSubmitInvoice(formData) {
    setIsSubmittingInvoice(true)

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      formData.fileForUserId = fileForUserDetails.fileForUserId;
      formData.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      formData.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        securedSendRequest.execute(
          'POST',
          NbciUsInvoiceApiUrls.SUBMIT,
          DataFlattener.unflatten({ ...data, ...formData }),
          (response) => {
            var data = response.data
            var errorMessages = data.errorMessages
            if (
              response.status == 200 &&
              data != null &&
              errorMessages == null
            ) {
              showNotification('success', 'Invoice submitted successfully.')
              history.push(pathMap.NBCI_US_INVOICE_LIST_VIEW)
            } else {
              showNotification('error', errorMessages)
            }
          },
          (error) => console.error(error)
        )
        resolve()
      }, 500)
    }).finally(() => setIsSubmittingInvoice(false))
  }

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading || isSubmitting}
            onClick={() => setAlertDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                disabled={loading || isSubmitting}
                onClick={onSkip}
                size='medium'
              >
                Skip
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                disabled={loading || isSubmitting}
                onClick={() => onSetPrintFlagDialog(getValues())}
                size='medium'
                shouldShowProgress={isSubmittingInvoice}
              >
                Print Invoice Level Forms
              </CngButton>
            </Grid>
            {/* <Grid item xs='auto'>
              <CngButton
                disabled={loading || isSubmitting}
                onClick={handleSubmit(handleSubmitInvoice)}
                size='medium'
                shouldShowProgress={isSubmittingInvoice}
              >
                {translatedTextsObject.submitButton}
              </CngButton>
            </Grid> */}
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading || isSubmitting}
                type='submit'
                size='medium'
              >
                Save as draft
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={onSaveAndContinue}
                type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Save &amp; continue
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={alertDialog}
        onClose={() => setAlertDialog(false)}
        onCancel={() => setAlertDialog(false)}
        onConfirm={() => {
          reset()
          setAlertDialog(false)
          setInvoiceIsDirty(false)
        }}
        title='Discard'
      >
        All progress in this session will be lost and can't be restored. Are you
        sure about this?
      </AlertDialog>
    </>
  )
}
