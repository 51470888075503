import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

function makeValidationSchema() {
  const regexAlphaNumeric = ValidationRegex.regexAlphaNumeric
  const errMsgAlphaNumeric =
    'Please enter alpha numeric values other than <,>,*,~'

  return Yup.object({
    reference: Yup.string().nullable(),
    referenceNumber: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(50, 'Max length allowed is 50')
  })
}

export default makeValidationSchema
