import { Grid, Typography } from '@material-ui/core'
import NbciInvoiceResponseApiUrls from '../../../apiUrls/NbciInvoiceResponseApiUrls'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { constants, useTranslation } from 'cng-web-lib'
import NbciUsInvoiceKeys from '../../../constants/locale/key/NbciUsInvoice'
import Namespace from '../../../constants/locale/Namespace'
import { USInvoiceLookupsContext } from '../lookups/USInvoiceLookupsContext'
import CngSection from '../../../components/cngcomponents/CngSection'
import Table from '../../../components/aciacehighway/Table'
import moment from 'moment'

const {
  filter: { EQUAL }
} = constants

function UsInvoiceResponse() {
  const { id } = useParams()

  const { getLookupValue } = useContext(USInvoiceLookupsContext)
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const title = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.RESPONSE_TAB
    )
    const invoiceNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.INVOICE_NUMBER
    )
    const receiveDatetime = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.RECEIVED_DATE
    )
    const status = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.STATUS
    )
    const errorTextLabel = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.ERROR_TEXT_LABEL
    )
    const entryNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.ENTRY_NUMBER
    )

    return {
      title,
      invoiceNumber,
      receiveDatetime,
      status,
      entryNo,
      errorTextLabel
    }
  }

  const columns = [
    {
      field: 'invoiceNumber',
      title: translatedTextsObject.invoiceNumber
    },
    {
      field: 'receiveDatetime',
      title: translatedTextsObject.receiveDatetime,
      render: (rowData) =>
        rowData.receiveDatetime &&
        moment(rowData.receiveDatetime).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: 'status',
      title: translatedTextsObject.status,
      render: (rowData) => getLookupValue('responseStatus', rowData.status)
    },
    {
      field: 'entryNo',
      title: translatedTextsObject.entryNo,
    },
    {
      field: 'errorCode',
      title: translatedTextsObject.errorTextLabel,
      render: (rowData) => (
        <Grid container spacing={1}>
          {rowData.errorResponses.map((responseErrorDTOs) => (
            <Grid item xs={12} key={responseErrorDTOs.id}>
              <Typography variant='body2'>
                {responseErrorDTOs.errorDesc}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )
    }
  ]

  return (
    <CngSection title={translatedTextsObject.title}>
      <Table
        columns={columns}
        fetch={{ url: NbciInvoiceResponseApiUrls.GET }}
        fetchFilters={[
          { field: 'headerId', operator: EQUAL, value: id },
          { field: 'docType', operator: EQUAL, value: 'NBCIUSINV' }
        ]}
        variant='outlined'
      />
    </CngSection>
  )
}

export default UsInvoiceResponse
