import React, { useContext, useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../constants/locale/key/NbciUsInvoice'
import { USInvoiceLookupsContext } from '../lookups/USInvoiceLookupsContext'
import StatusBar from '../../../components/aciacehighway/StatusBar'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import LocalTable from '../../../components/aciacehighway/LocalTable'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngField from '../../../components/cngcomponents/CngField'
import AdditionalFormInfoSection from './view/AdditionalFormInfoSection'
import InvoiceDetailsTable from './view/InvoiceDetailsTable'
import InvoiceSummarySection from './view/InvoiceSummarySection'
import UsInvoiceResponse from './UsInvoiceResponse'
import ActivityLog from './ActivityLog'
import moment from 'moment'
import _ from 'lodash'
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  button: { CngButton },
  CngDialog
} = components

const useStyles = makeStyles((theme) => ({
  transportIcon: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
    height: 32,
    width: 32
  }
}))

function InvoiceViewContent(props) {
  const { data, history, showNotification } = props

  const [tradePartyDialog, setTradePartyDialog] = useState({
    open: false,
    party: null
  })
  const { lookups, getLookupValue, getCountryStateLabel } = useContext(
    USInvoiceLookupsContext
  )
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const classes = useStyles()

  function makeTranslatedTextsObject() {
    const invoiceNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.INVOICE_NUMBER
    )
    const scacCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.SCAC_CODE
    )
    const invoiceDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.INVOICE_DATE
    )
    const carrierName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CARRIER_NAME
    )
    const purchaseOrderNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.PURCHASE_ORDER_NUMBER
    )
    const shipmentControlNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.SHIPMENT_CONTROL_NUMBER
    )
    const currency = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CURRENCY
    )
    const foreignPortOfExit = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FOREIGN_PORT_OF_EXIT
    )
    const totalShipmentQuantity = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TOTAL_SHIPMENT_QUANTITY
    )
    const portofEntry = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.PORTOF_ENTRY
    )
    const totalShipmentQuantityUOM = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TOTAL_SHIPMENT_QUANTITY_U_O_M
    )
    const transportationMode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TRANSPORTATION_MODE
    )
    const totalItemsGrossWeight = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TOTAL_ITEMS_GROSS_WEIGHT
    )
    const entryNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.ENTRY_NUMBER
    )
    const totalItemsNetWeight = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TOTAL_ITEMS_NET_WEIGHT
    )
    const exportCarrier = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.EXPORT_CARRIER
    )
    const status = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.STATUS
    )
    const cargoSummary = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceCargoSummary.TITLE
    )
    const tradeParty = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.TITLE
    )
    const partyIdentifierNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_IDENTIFIER_NUMBER
    )
    const partyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_NAME
    )
    const partyType = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_TYPE
    )
    const partyAddress = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_ADDRESS
    )
    const partyCountry = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_COUNTRY
    )
    const ciForm = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.TITLE
    )
    const partyBilled = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.PARTY_BILLED
    )
    const partyBilledOthers = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.PARTY_BILLED_OTHERS
    )
    const shipmentDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.SHIPMENT_DATE
    )
    const arrivalDateTime = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.ARRIVAL_DATE_TIME
    )
    const markPackLoad = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.MARK_PACK_LOAD
    )
    const termOfSale = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.TERM_OF_SALE
    )
    const reasonForExportation = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.REASON_FOR_EXPORTATION
    )
    const transactionParties = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.TRANSACTION_PARTIES
    )
    const fbiPurchasePrice = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.FBI_PURCHASE_PRICE
    )
    const ffdPurchasePrice = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.FFD_PURCHASE_PRICE
    )
    const bfiPurchasePrice = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.BFI_PURCHASE_PRICE
    )
    const dfiPurchasePrice = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.DFI_PURCHASE_PRICE
    )
    const goodsSold = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.GOODS_SOLD
    )
    const originStateCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.ORIGIN_STATE_CODE
    )
    const originCountryCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.ORIGIN_COUNTRY_CODE
    )
    const destinationCountryCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.DESTINATION_COUNTRY_CODE
    )
    const destinationStateCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.DESTINATION_STATE_CODE
    )
    const transportTermsCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.TRANSPORT_TERMS_CODE
    )
    const shipperRegNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.SHIPPER_REG_NO
    )
    const declNewStmt = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.DECL_NEW_STMT
    )
    const tscaStatement = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.TSCA_STATEMENT
    )
    const usProForma = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.US_PRO_FORMA
    )
    const usGoods = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.US_GOODS
    )
    const lowValueNafta = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.LOW_VALUE_NAFTA
    )
    const usShipExp = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.US_SHIP_EXP
    )
    const fdaSub = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.FDA_SUB
    )
    const usGoods10219 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.US_GOODS10219
    )
    const reference = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceCargoSummary.REFERENCE
    )
    const referenceNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceCargoSummary.REFERENCE_NUMBER
    )

    return {
      invoiceNumber,
      invoiceDate,
      carrierName,
      purchaseOrderNumber,
      exportCarrier,
      scacCode,
      shipmentControlNumber,
      currency,
      foreignPortOfExit,
      portofEntry,
      transportationMode,
      totalShipmentQuantity,
      totalItemsGrossWeight,
      totalItemsNetWeight,
      totalShipmentQuantityUOM,
      entryNumber,
      status,
      cargoSummary,
      tradeParty,
      partyIdentifierNumber,
      partyType,
      partyName,
      partyAddress,
      partyCountry,
      ciForm,
      partyBilled,
      partyBilledOthers,
      shipmentDate,
      arrivalDateTime,
      markPackLoad,
      termOfSale,
      reasonForExportation,
      originCountryCode,
      originStateCode,
      destinationCountryCode,
      destinationStateCode,
      transportTermsCode,
      shipperRegNo,
      transactionParties,
      fbiPurchasePrice,
      ffdPurchasePrice,
      bfiPurchasePrice,
      dfiPurchasePrice,
      goodsSold,
      declNewStmt,
      tscaStatement,
      usProForma,
      usGoods,
      lowValueNafta,
      usShipExp,
      usGoods10219,
      fdaSub,
      reference,
      referenceNumber,
    }
  }

  const { invoiceDetails, invoiceSummary } = data

  const ciForm = data.ciForm?.[0]

  const tradePartyTableColumns = [
    {
      title: translatedTextsObject.partyIdentifierNumber,
      field: 'partyIdentifierNumber',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.partyType,
      field: 'partyType',
      filterConfig: {
        type: 'select',
        options: lookups?.partyType
          ? Object.entries(lookups.partyType).map(([code, label]) => ({
              label: label,
              value: code
            }))
          : []
      },
      render: (data) => getLookupValue('partyType', data.partyType)
    },
    {
      title: translatedTextsObject.partyName,
      field: 'partyName',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.partyAddress,
      field: 'partyAddress',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.partyCountry,
      field: 'partyCountry',
      filterConfig: { type: 'textfield' },
      render: (data) => getLookupValue('country', data.partyCountry)
    }
  ]

  function renderTransportModeIcon(mode) {
    function getIconName() {
      switch (mode) {
        case 'M':
          return 'truck'
        case 'A':
          return 'plane'
        case 'R':
          return 'train-track'
        case 'O':
          return 'ship'

        default:
          return null
      }
    }

    const iconName = getIconName()

    return (
      iconName && (
        <Avatar className={classes.transportIcon}>
          <FontAwesomeIcon
            color='inherit'
            icon={['fal', getIconName()]}
            size='xs'
          />
        </Avatar>
      )
    )
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StatusBar status={data.invHeaderstatus} />
        </Grid>
        <Grid item xs={12}>
          <CngSection title='Invoice information'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.invoiceNumber}>
                  {data.invoiceNumber}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.invoiceDate}>
                  {data.invoiceDate &&
                    moment(data.invoiceDate).format('D MMM YYYY')}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.carrierName}>
                  {data.carrierName}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.purchaseOrderNumber}>
                  {data.purchaseOrderNumber}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.exportCarrier}>
                  {data.exportCarrier}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField
                  label={[
                    translatedTextsObject.scacCode,
                    translatedTextsObject.shipmentControlNumber
                  ].join(' - ')}
                >
                  {[
                    data.scacCode,
                    data.shipmentControlNumber
                  ].join(' - ')}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.currency}>
                  {data.currency}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.foreignPortOfExit}>
                  {data.foreignPortOfExit}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.portofEntry}>
                  {data.portofEntry}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.transportationMode}>
                  <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                    {renderTransportModeIcon(data.transportationMode)}
                    <Typography variant='inherit'>
                      {getLookupValue('transportationMode', data.transportationMode)}
                    </Typography>
                  </Box>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.totalShipmentQuantity}>
                  {data.totalShipmentQuantity}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.totalShipmentQuantityUOM}>
                  {data.totalShipmentQuantityUOM}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.totalItemsGrossWeight}>
                  {data.totalItemsGrossWeight}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.totalItemsNetWeight}>
                  {data.totalItemsNetWeight}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.entryNumber}>
                  {data.entryNumber}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.status}>
                  {data.status}
                </CngField>
              </Grid>
            </Grid>
          </CngSection>
        </Grid>
        {!_.isEmpty(data.tradeParty) && (
          <Grid item xs={12}>
            <Card variant='outlined'>
              <LocalTable
                columns={tradePartyTableColumns}
                data={data.tradeParty}
                header={
                  <Typography
                    component='div'
                    variant='h5'
                    style={{ fontWeight: 600 }}
                  >
                    {translatedTextsObject.tradeParty}
                  </Typography>
                }
                onRowClick={(data) =>
                  setTradePartyDialog({ open: true, party: data })
                }
              />
              <TradePartyDialog
                getCountryStateLabel={getCountryStateLabel}
                getLookupValue={getLookupValue}
                open={tradePartyDialog.open}
                onClose={() =>
                  setTradePartyDialog({ open: false, party: null })
                }
                party={tradePartyDialog.party}
              />
            </Card>
          </Grid>
        )}
        {ciForm && (
          <>
            <Grid item xs={12}>
              <CiFormSection
                data={ciForm}
                getLookupValue={getLookupValue}
                getCountryStateLabel={getCountryStateLabel}
                translatedTextsObject={translatedTextsObject}
              />
            </Grid>
            {!_.isEmpty(ciForm.nbciusinvoiceCargoSummary) && (
              <Grid item xs={12}>
                <CngSection title={translatedTextsObject.cargoSummary}>
                  <Grid container spacing={1}>
                    {ciForm.nbciusinvoiceCargoSummary.map((summary, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.reference}>
                                {getLookupValue('reference', summary.reference)}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.referenceNumber}>
                                {summary.referenceNumber}
                              </CngField>
                            </Grid>
                          </Grid>
                        </Grid>
                        {index + 1 < ciForm.nbciusinvoiceCargoSummary.length && (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                </CngSection>
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12}>
          <AdditionalFormInfoSection
            invoiceData={data}
            showNotification={showNotification}
          />
        </Grid>
        <Grid item xs={12}>
          <InvoiceDetailsTable
            data={invoiceDetails}
            showNotification={showNotification} 
          />
        </Grid>
        <Grid item xs={12}>
          <InvoiceSummarySection
            invoiceSummary={invoiceSummary}
            showNotification={showNotification} 
          />
        </Grid>
        <Grid item xs={12}>
          <UsInvoiceResponse
            history={history}
            showNotification={showNotification} 
          />
        </Grid>
        <Grid item xs={12}>
          <ActivityLog invoiceId={data.id} />
        </Grid>
      </Grid>
    </>
  )
}

function TradePartyDialog(props) {
  const { getCountryStateLabel, getLookupValue, onClose, open, party } = props

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const tradeParty = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.TITLE
    )
    const partyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_NAME
    )
    const partyType = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_TYPE
    )
    const partyIdentifierCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_IDENTIFIER_CODE
    )
    const partyIdentifierNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_IDENTIFIER_NUMBER
    )
    const partyScacCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_SCAC_CODE
    )
    const partyAddress = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_ADDRESS
    )
    const partyCity = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_CITY
    )
    const partyState = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_STATE
    )
    const partyCountry = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_COUNTRY
    )
    const partyPostalCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_POSTAL_CODE
    )
    const partyLocalContactName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_LOCAL_CONTACT_NAME
    )
    const partyTelephoneNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_TELEPHONE_NUMBER
    )
    const partyFax = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_FAX
    )
    const dba = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.DBA
    )
    const partyEmail = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_EMAIL
    )

    return {
      tradeParty,
      partyType,
      dba,
      partyName,
      partyScacCode,
      partyIdentifierCode,
      partyIdentifierNumber,
      partyAddress,
      partyCity,
      partyCountry,
      partyState,
      partyPostalCode,
      partyLocalContactName,
      partyTelephoneNumber,
      partyFax,
      partyEmail,
    }
  }

  return (
    <CngDialog
      customDialogContent={
        <Box p={2}>
          {party ? (
            <CngSection title='Party Information'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.partyType}>
                        {getLookupValue('partyType', party.partyType)}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={9}>
                      <CngField label={translatedTextsObject.dba}>
                        {party.dba}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={9}>
                      <CngField label={translatedTextsObject.partyName}>
                        {party.partyName}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.partyScacCode}>
                        {party.partyScacCode}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={[
                        translatedTextsObject.partyIdentifierCode,
                        translatedTextsObject.partyIdentifierNumber
                      ].join(' & ')}>
                        {[
                          party.partyIdentifierCode,
                          party.partyIdentifierNumber
                        ].join(' - ')}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={9}>
                      <CngField label={translatedTextsObject.partyAddress}>
                        {party.partyAddress}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.partyCity}>
                        {party.partyCity}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.partyCountry}>
                        {party.partyCountry}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.partyState}>
                        {getCountryStateLabel(party.partyCountry, party.partyState)}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.partyPostalCode}>
                        {party.partyPostalCode}
                      </CngField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid alignItems='center' container spacing={2}>
                    <Grid item xs='auto'>
                      <Typography variant='body2' color='textSecondary'>
                        Local Contact Information
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.partyLocalContactName}>
                        {party.partyLocalContactName}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.partyTelephoneNumber}>
                        {party.partyTelephoneNumber}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.partyFax}>
                        {party.partyFax}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.partyEmail}>
                        {party.partyEmail}
                      </CngField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CngSection>
          ) : (
            <Box display='flex' alignItems='center' justifyContent='center'>
              <CircularProgress />
            </Box>
          )}
        </Box>
      }
      dialogAction={<CngButton onClick={onClose}>OK</CngButton>}
      dialogTitle={translatedTextsObject.tradeParty}
      maxWidth='md'
      fullWidth
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

function CiFormSection(props) {
  const { data, getLookupValue, getCountryStateLabel, translatedTextsObject } =
    props
  const theme = useTheme()

  const {
    tscaStatement,
    usProForma,
    usGoods,
    lowValueNafta,
    usShipExp,
    usGoods10219,
    fdaSub,
  } = data

  const declarations = [
    {
      name: 'tscaStatement',
      value: tscaStatement
    },
    {
      name: 'usProForma',
      value: usProForma
    },
    {
      name: 'usGoods',
      value: usGoods
    },
    {
      name: 'lowValueNafta',
      value: lowValueNafta
    },
    {
      name: 'usShipExp',
      value: usShipExp
    },
    {
      name: 'usGoods10219',
      value: usGoods10219
    },
    {
      name: 'fdaSub',
      value: fdaSub
    }
  ].filter((declaration) => declaration.value === 'Y')

  return (
    <Card variant='outlined'>
      <Box p={2}>
        <Typography style={{ fontWeight: 600 }}>
          Additional Information
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Box bgcolor='grey.100' px={2} py={1}>
            <Typography color='textSecondary' style={{ fontWeight: 600 }}>
              {translatedTextsObject.ciForm}
            </Typography>
          </Box>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.partyBilled}>
                  {getLookupValue('partyBilled', data.partyBilled)}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.partyBilledOthers}>
                  {data.partyBilledOthers}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.shipmentDate}>
                  {data.shipmentDate &&
                    moment(data.shipmentDate).format('D MMM YYYY')}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.arrivalDateTime}>
                  {data.arrivalDateTime &&
                    moment(data.arrivalDateTime).format('D MMM YYYY')}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.markPackLoad}>
                  {data.markPackLoad}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CngField label={translatedTextsObject.termOfSale}>
                  {data.termOfSale}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CngField label={translatedTextsObject.reasonForExportation}>
                  {data.reasonForExportation}
                </CngField>
              </Grid>
            </Grid>
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box bgcolor='grey.100' px={2} py={1}>
            <Typography color='textSecondary' style={{ fontWeight: 600 }}>
              FOB related instructions
            </Typography>
          </Box>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.originCountryCode}>
                  {data.originCountryCode && (
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('country', data.originCountryCode)}
                      <TinyChip
                        label={data.originCountryCode}
                        variant='outlined'
                      />
                    </Typography>
                  )}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.originStateCode}>
                  {data.originStateCode && (
                    <Typography component='div' variant='inherit'>
                      {getCountryStateLabel(
                        data.originCountryCode,
                        data.originStateCode
                      )}
                      <TinyChip
                        label={data.originStateCode}
                        variant='outlined'
                      />
                    </Typography>
                  )}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.destinationCountryCode}>
                  {data.destinationCountryCode && (
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('country', data.destinationCountryCode)}
                      <TinyChip
                        label={data.destinationCountryCode}
                        variant='outlined'
                      />
                    </Typography>
                  )}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.destinationStateCode}>
                  {data.destinationStateCode && (
                    <Typography component='div' variant='inherit'>
                      {getCountryStateLabel(
                        data.destinationCountryCode,
                        data.destinationStateCode
                      )}
                      <TinyChip
                        label={data.destinationStateCode}
                        variant='outlined'
                      />
                    </Typography>
                  )}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CngField label={translatedTextsObject.transportTermsCode}>
                  {getLookupValue('transportTermsCode', data.transportTermsCode)}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CngField label={translatedTextsObject.shipperRegNo}>
                  {data.shipperRegNo}
                </CngField>
              </Grid>
            </Grid>
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box bgcolor='grey.100' px={2} py={1}>
            <Typography color='textSecondary' style={{ fontWeight: 600 }}>
              Information disclosure
            </Typography>
          </Box>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <CngField label={translatedTextsObject.transactionParties}>
                  {data.transactionParties === 'Y' ? 'Yes' : 'No'}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CngField label={translatedTextsObject.fbiPurchasePrice}>
                  {data.fbiPurchasePrice === 'Y' ? 'Yes' : 'No'}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CngField label={translatedTextsObject.ffdPurchasePrice}>
                  {data.ffdPurchasePrice === 'Y' ? 'Yes' : 'No'}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CngField label={translatedTextsObject.bfiPurchasePrice}>
                  {data.bfiPurchasePrice === 'Y' ? 'Yes' : 'No'}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CngField label={translatedTextsObject.dfiPurchasePrice}>
                  {data.dfiPurchasePrice === 'Y' ? 'Yes' : 'No'}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CngField label={translatedTextsObject.goodsSold}>
                  {data.goodsSold === 'Y' ? 'Yes' : 'No'}
                </CngField>
              </Grid>
            </Grid>
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box bgcolor='grey.100' px={2} py={1}>
            <Typography color='textSecondary' style={{ fontWeight: 600 }}>
              Document declaration
            </Typography>
          </Box>
          <Box p={2}>
            <Grid container spacing={4}>
              {declarations.map((declaration) => (
                <Grid key={declaration.name} item xs='auto'>
                  <Box display='flex' alignItems='center' style={{ gap: 16 }}>
                    <FontAwesomeIcon
                      color={theme.palette.primary.main}
                      icon={['fal', 'check']}
                    />
                    <Typography variant='body2'>
                      {translatedTextsObject[declaration.name]}
                    </Typography>
                  </Box>
                </Grid>
              ))}
              <Grid item xs={12}>
                <CngField label={translatedTextsObject.declNewStmt}>
                  {data.declNewStmt}
                </CngField>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}

export default InvoiceViewContent
