import React, {useEffect , useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import NbciUsInvoiceApiUrls from 'src/apiUrls/NbciUsInvoiceApiUrls'
import { format } from 'date-fns'
import Table from '../../../components/aciacehighway/Table'

const {
  button: { CngButton },
  CngDialog,
} = components



function PrintFlagLineIteamDialog(props) {
  const {
    openDialog,
    onClose  } = props

  const { securedSendRequest } = useServices();
  console.log("Inside printFalgDialog: " +JSON.stringify(openDialog.invoiceDetails) );
  return (
    <>
    <CngDialog
      customDialogContent={
        <Paper>
          <Box m={2} mt={1}>
            <Box mt={0.5}>
            
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              //disabled ={!DOFormSet}
             disabled = {openDialog.invoiceDetails && openDialog.invoiceDetails.nbciusinvoiceDetailsDOTForm && openDialog.invoiceDetails.nbciusinvoiceDetailsDOTForm.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_DOT_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_DOT, openDialog.invoiceDetails, onSuccess, onError, onComplete,config,null);  
              
              }}
              >DOT
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = { openDialog.invoiceDetails && openDialog.invoiceDetails.nbciusinvoiceDetailsFCCForm && openDialog.invoiceDetails.nbciusinvoiceDetailsFCCForm.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_FCC_740_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_FCC, openDialog.invoiceDetails, onSuccess, onError, onComplete,config,null);  
              
              }}
              >FCC740
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.invoiceDetails && openDialog.invoiceDetails.nbciusinvoiceDetailsFDAForm && openDialog.invoiceDetails.nbciusinvoiceDetailsFDAForm.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_FDA_2877_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_FDA, openDialog.invoiceDetails, onSuccess, onError, onComplete,config,null);  
              
              }}
              >FDA_2877
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.invoiceDetails && openDialog.invoiceDetails.nbciusinvoiceDetailsEPA35201Form && openDialog.invoiceDetails.nbciusinvoiceDetailsEPA35201Form.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_EPA_3520_1_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_EPA_1, openDialog.invoiceDetails, onSuccess, onError, onComplete,config,null);  
              
              }}
              >EPA_3520_1
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.invoiceDetails && openDialog.invoiceDetails.nbciusinvoiceDetailsEPA352021Form && openDialog.invoiceDetails.nbciusinvoiceDetailsEPA352021Form.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_EPA_3520_21_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_EPA_21, openDialog.invoiceDetails, onSuccess, onError, onComplete,config,null);  
              
              }}
              >EPA_3520_21
              </CngButton>
            </Box>
           </Box>
         </Paper>
      }
      dialogTitle={
        <>
          <b>Print Forms</b>
          <CloseDialogIconButton onClick={onClose} />
        </>
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={openDialog.open}
    />
    </>
  )
}

export default PrintFlagLineIteamDialog