import React from 'react'
import { constants, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import NbciUsInvoiceKeys from '../../../constants/locale/key/NbciUsInvoice'
import NbciUsInvoiceApiUrls from '../../../apiUrls/NbciUsInvoiceApiUrls'
import Namespace from '../../../constants/locale/Namespace'
import CngSection from '../../../components/cngcomponents/CngSection'
import Table from '../../../components/aciacehighway/Table'
import moment from 'moment'

const {
  filter: { EQUAL }
} = constants

function ActivityLog() {
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.NBCI_US_INVOICE])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const activityLogTitle = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.ACTIVITYLOG_TITLE
    )
    const createdDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CREATED_DATE
    )
    const loginUserId = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.LOGIN_USER_ID
    )
    const functionCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FUNCTION_CODE
    )
    const auditDescription = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.AUDIT_DESC
    )

    return {
      activityLogTitle,
      createdDate,
      loginUserId,
      functionCode,
      auditDescription
    }
  }

  const columns = [
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (rowData) =>
        rowData.createdDate &&
        moment(rowData.createdDate).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: 'userId',
      title: translatedTextsObject.loginUserId
    },
    {
      field: 'functionCode',
      title: translatedTextsObject.functionCode
    },
    {
      field: 'auditDescription',
      title: translatedTextsObject.auditDescription
    }
  ]

  return (
    <CngSection title={translatedTextsObject.activityLogTitle}>
      <Table
        columns={columns}
        fetch={{ url: NbciUsInvoiceApiUrls.GET_AUDIT_LOGS }}
        fetchFilters={[
          {
            field: 'keyReferenceNo',
            operator: EQUAL,
            value: 'NBCI_US_INVOICE_' + id
          }
        ]}
        variant='outlined'
      />
    </CngSection>
  )
}

export default ActivityLog
