import React, {useState} from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import NbciUsInvoiceApiUrls from '../../../apiUrls/NbciUsInvoiceApiUrls'
import FormProperties from './FormProperties'
import InvoiceDetailsTable from './invoiceDetails/InvoiceDetailsTable'
import InvoiceSummarySection from './invoiceSummary/InvoiceSummarySection'
import Grid from '@material-ui/core/Grid'
import PrintFlagDialog from '../../../views/nbci/usinvoice/PrintFlagDialog'
import PrintFlagLineIteamDialog from '../../../views/nbci/usinvoice/PrintFlagLineIteamDialogAll'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'

const {
  button: { CngButton },
  form: { CngForm }
} = components

const { FormState } = constants

function InvoiceDetailsAndSummaryInvoiceSection(props) {
  const {
    invoiceData,
    onBack,
    onDiscard,
    // onSetInvoiceData,
    onSetLoading,
    onSkip,
    onSubmit,
    showNotification,
    isPendingSave,
    isPendingSaveShipmentSummary,
    isNotSaved
  } = props

  const { updateRecord, securedSendRequest } = useServices()
  const [invoiceStateData, setInvoiceStateData] = useState(invoiceData)
  const [alertDialog, setAlertDialog] = useState(false)
  const [printAlertDialog, setPrintAlertDialog] = useState({
    open: false,
    manifest: null,
    type: null
  })
  
  function prePrint(data, type) {
    if (isPendingSave.current || isPendingSaveShipmentSummary.current) {
      alert("Changes have not been saved. Please save the changes before proceed.")
    } else {
      if (isNotSaved.current) {
        setPrintAlertDialog({ open: true, manifest: data, type: type })
      } else {
        if (type === 'invoice') {
          setPrintFlagDialog({ open: true, manifest: invoiceData.current })
        } else {
          setPrintLineItemFlagDialog({ open: true, manifest: invoiceData.current })
        }
      }
    }
  }

  function preSave() {
    if (isPendingSave.current || isPendingSaveShipmentSummary.current) {
      alert("Changes have not been saved. Please save the changes before proceed.")
    } else {
      handleSave(invoiceData.current, null)
    }
  }

  function handleSave(data, printType) {
    onSetLoading(true)

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    updateRecord.execute(
      NbciUsInvoiceApiUrls.PUT,
      FormProperties.toServerDataFormat(data),
      (response) => {
        showNotification('success', 'Invoice updated.')
        response.invoiceDetails = response.invoiceDetails.map(
          (invoice, index) => ({ ...invoice, _id: index })
        )
        response.invoiceSummary = response.invoiceSummary.map(
          (summary, index) => ({ ...summary, _id: index })
        )
        invoiceData.current = response
        isNotSaved.current = false
        if (null !== printType) {
          if (printType === 'invoice') {
            setPrintFlagDialog({ open: true, manifest: invoiceData.current })
          } else {
            setPrintLineItemFlagDialog({ open: true, manifest: invoiceData.current })
          }
        }
      },
      (error) => {
        const { data } = error.response

        if (data && data.errorMessages) {
          showNotification('error', data.errorMessages)
        }
      },
      () => onSetLoading(false)
    )
  }

  const [printFlagDialog, setPrintFlagDialog] = useState({
    open: false,
    manifest: null
  })
  const [printLineItemFlagDialog, setPrintLineItemFlagDialog] = useState({
    open: false,
    manifest: null
  })

  return (
    <>
    <CngForm
      formikProps={{
          initialValues: invoiceData.current,
        makeValidationSchema: FormProperties.formikProps.makeValidationSchema,
          // onSubmit: preSave
      }}
      formState={FormState.COMPLETED}
      innerForm
      renderBodySection={() => (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InvoiceDetailsTable invoiceData={invoiceData} setInvoiceStateData={setInvoiceStateData} isNotSaved={isNotSaved} />
          </Grid>
          <Grid item xs={12}>
            <InvoiceSummarySection invoiceData={invoiceData} isPendingSave={isPendingSave} isPendingSaveShipmentSummary={isPendingSaveShipmentSummary} invoiceStateData={invoiceStateData} isNotSaved={isNotSaved} />
          </Grid>
        </Grid>
      )}
      renderButtonSection={() => (
        <ButtonSection
          onBack={onBack}
          onDiscard={() => setAlertDialog(true)}
          onSkip={onSkip}
          onSetPrintFlagDialog={() => prePrint(invoiceData.current, 'invoice')}
          onSetPrintLineItemFlagDialog={() => prePrint(invoiceData.current, 'lineItem')}
          onSubmit={onSubmit}
          preSave={preSave}
        />
      )}
    />
      <PrintFlagDialog
        openDialog={printFlagDialog}
        onClose={() => setPrintFlagDialog({ open: false, manifest: null })}
        showNotification={showNotification}
      />
      <PrintFlagLineIteamDialog
        openDialog={printLineItemFlagDialog}
        onClose={() => setPrintLineItemFlagDialog({ open: false, manifest: null })}
        showNotification={showNotification}
      />
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, Save and Print'
        open={printAlertDialog.open}
        onClose={() => setPrintAlertDialog({ open: false, manifest: null, type: null })}
        onCancel={() => setPrintAlertDialog({ open: false, manifest: null, type: null })}
        onConfirm={() => {
          setPrintAlertDialog({ open: false, manifest: null, type: null })
          handleSave(printAlertDialog.manifest, printAlertDialog.type)
        }}
        title='Alert'
      >
        Changes have not been saved. Do you want to proceed with saving then print?
      </AlertDialog>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={alertDialog}
        onClose={() => setAlertDialog(false)}
        onCancel={() => setAlertDialog(false)}
        onConfirm={() => {
          setAlertDialog(false)
          onDiscard(0)
          isPendingSave.current = false
          isPendingSaveShipmentSummary.current = false
          isNotSaved.current = false
        }}
        title='Discard'
      >
        All progress in this session will be lost and can't be restored. Are you
        sure about this?
      </AlertDialog>
   </> 
  )
}

function ButtonSection(props) {
  const { onBack, onDiscard, onSkip, onSetPrintFlagDialog, onSetPrintLineItemFlagDialog, onSubmit, preSave } = props

  return (
    <Grid container justify='space-between' spacing={3}>
      <Grid item xs={12} md='auto'>
        <CngButton color='secondary' onClick={onDiscard} size='medium'>
          Discard
        </CngButton>
      </Grid>
      <Grid item xs={12} md='auto'>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onBack} size='medium'>
              Back
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onSkip} size='medium'>
              Skip
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onSetPrintFlagDialog} size='medium'>
              Print Invoice Level Forms
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onSetPrintLineItemFlagDialog} size='medium'>
              Print Line Item Forms
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='primary' onClick={preSave} size='medium'>
              Save as Draft
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='primary' onClick={onSubmit} size='medium'>
              Submit
            </CngButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InvoiceDetailsAndSummaryInvoiceSection
