import React from 'react'
import { components } from 'cng-web-lib'
import UsInvoiceResponse from './UsInvoiceResponse'
import ActivityLog from './ActivityLog'
import USInvoiceLookupsProvider from '../lookups/USInvoiceLookupsContext'
import Grid from '@material-ui/core/Grid'

const {
  button: { CngButton }
} = components

function ResponseLogAndHistorySection(props) {
  const { onBack, onDiscard, onSubmit } = props

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <USInvoiceLookupsProvider>
              <UsInvoiceResponse />
            </USInvoiceLookupsProvider>
          </Grid>
          <Grid item xs={12}>
            <ActivityLog />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs={12} md='auto'>
            <CngButton color='secondary' onClick={onDiscard} size='medium'>
              Discard
            </CngButton>
          </Grid>
          <Grid item xs={12} md='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton color='secondary' onClick={onBack} size='medium'>
                  Back
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton color='primary' onClick={onSubmit} size='medium'>
                  Submit
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResponseLogAndHistorySection
