import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import UsDeclarationsApiUrls from '../../../apiUrls/UsDeclarationsApiUrls'
import pathMap from '../../../paths/pathMap'
import FormProperties from './FormProperties'
import { Box, Card, Grid } from '@material-ui/core'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage(props) {
  const { history, onSetLoading, showNotification } = props

  return (
    <Card>
      <Box padding={2}>
        <CngAddForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: UsDeclarationsApiUrls.POST,
            successRedirect: pathMap.US_DECLARATIONS_LIST_VIEW
          }}
          renderButtonSection={(loading) => (
            <ButtonSection
              loading={loading}
              onDiscard={() => history.push(pathMap.US_DECLARATIONS_LIST_VIEW)}
              onSetLoading={onSetLoading}
            />
          )}
        />
      </Box>
    </Card>
  )
}

function ButtonSection(props) {
  const { loading, onDiscard, onSetLoading } = props
  const [alertDialog, setAlertDialog] = useState(false)

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setAlertDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <CngButton
            disabled={loading}
            type='submit'
            size='medium'
          >
            Save
          </CngButton>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={alertDialog}
        onClose={() => setAlertDialog(false)}
        onCancel={() => setAlertDialog(false)}
        onConfirm={onDiscard}
        title='Discard'
      >
        All progress in this session will be lost and can't be restored. Are you
        sure about this?
      </AlertDialog>
    </>
  )
}

export default withFormState(AddPage)
