import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import moment from 'moment'
import CargoSummaryMakeValidationSchema from './CargoSummaryMakeValidationSchema'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumeric = ValidationRegex.regexAlphaNumeric
  const errMsgAlphaNumeric =
    'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric

  return Yup.object({
    partyBilled: Yup.string().nullable().required(requiredMessage),
    partyBilledOthers: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(12, 'Max length allowed is 12'),
    shipmentDate: Yup.lazy((value) => value === '' || value === null ? Yup.date().nullable() : Yup.date()
      .validFormat()
      .typeError(dateTypeErrorMessage))
      ,
    arrivalDateTime: Yup.string()
      .test(
        'arrivalDateTime',
        'Please select a valid Date Time in yyyy-mm-dd HH:mm format.',
         value => { return moment(value).isValid() || value==null; }
      )
      .nullable(),
    markPackLoad: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(80, 'Max length allowed is 80'),
    termOfSale: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(80, 'Max length allowed is 80'),
    reasonForExportation: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(35, 'Max length allowed is 35'),
    transactionParties: Yup.string().nullable().required(requiredMessage),
    fbiPurchasePrice: Yup.string().nullable(),
    ffdPurchasePrice: Yup.string().nullable().required(requiredMessage),
    bfiPurchasePrice: Yup.string().nullable(),
    dfiPurchasePrice: Yup.string().nullable(),
    goodsSold: Yup.string().nullable(),
    originStateCode: Yup.string().nullable(),
    originCountryCode: Yup.string().nullable().required(requiredMessage),
    destinationCountryCode: Yup.string().nullable().required(requiredMessage),
    destinationStateCode: Yup.string().nullable(),
    transportTermsCode: Yup.string().nullable().required(requiredMessage),
    shipperRegNo: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(50, 'Max length allowed is 50'),
    tscaStatement: Yup.string().nullable(),
    usProForma: Yup.string().nullable(),
    usGoods: Yup.string().nullable(),
    lowValueNafta: Yup.string().nullable(),
    usShipExp: Yup.string().nullable(),
    usGoods10219: Yup.string().nullable(),
    fdaSub: Yup.string().nullable(),
    declNewStmt: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(250, 'Max length allowed is 250'),
    nbciusinvoiceCargoSummary: Yup.array().of(CargoSummaryMakeValidationSchema(translate))
  })
}

export default makeValidationSchema
