import React, { useCallback, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import CngSection from '../../../components/cngcomponents/CngSection'
import AdditionalFormItem from '../components/AdditionalFormItem'
import { Box, Grid } from '@material-ui/core'
import LaceyActSection from './additionalFormSection/LaceyAct/LaceyActSection'
import USAPHISFormSection from './additionalFormSection/USAPHISForm/USAPHISFormSection'
import PackingListSection from './additionalFormSection/PackingList/PackingListSection'
import TscaFormSection from './additionalFormSection/TscaForm/TscaFormSection'
import BolFormSection from './additionalFormSection/BolForm/BolFormSection'
import FdabtaFormSection from './additionalFormSection/FdabtaForm/FdabtaFormSection'
import NaftaFormSection from './additionalFormSection/NaftaForm/NaftaFormSection'
import { useFormContext } from 'react-hook-form'
import NbciUsInvoiceApiUrls from 'src/apiUrls/NbciUsInvoiceApiUrls'
import { format } from 'date-fns'

const { CngDialog } = components

function AdditionalFormInformationSection({ setAddiFormsDirty }) {
  const [dialog, setDialog] = useState({ key: null, open: false })
  const { setValue, getValues, watch } = useFormContext()
  const { securedSendRequest } = useServices();
  const laceyAct = watch('laceyAct')
  const packingList = watch('packingList')
  const tscaform = watch('tscaform')
  const bolForm = watch('bolForm')
  const fdabtaform = watch('fdabtaform')
  const usAPHISForm = watch('usAPHISForm')
  const naftaForm = watch('naftaForm')

  function handleCloseDialog() {
    setDialog({ key: null, open: false })
  }

  function handleChange(fieldName, data) {
    setValue(fieldName, data)
    handleCloseDialog()
    setAddiFormsDirty(true)
  }

  const renderForm = useCallback(() => {
    switch (dialog.key) {
      case 'laceyAct':
        return (
          <LaceyActSection
            laceyAct={laceyAct}
            onClose={handleCloseDialog}
            onSubmit={(data) => handleChange('laceyAct', data)}
          />
        )
      case 'packingList':
        return (
          <PackingListSection
            packingList={packingList}
            onClose={handleCloseDialog}
            onSubmit={(data) => handleChange('packingList', data)}
          />
        )
      case 'tscaform':
        return (
          <TscaFormSection
            tscaForm={tscaform}
            onClose={handleCloseDialog}
            onSubmit={(data) => handleChange('tscaform', data)}
          />
        )
      case 'bolForm':
        return (
          <BolFormSection
            bolForm={bolForm}
            onClose={handleCloseDialog}
            onSubmit={(data) => handleChange('bolForm', data)}
          />
        )
      case 'fdabtaform':
        return (
          <FdabtaFormSection
            fdabtaForm={fdabtaform}
            onClose={handleCloseDialog}
            onSubmit={(data) => handleChange('fdabtaform', data)}
          />
        )
      case 'usAPHISForm':
        return (
          <USAPHISFormSection
            usAPHISForm={usAPHISForm}
            onClose={handleCloseDialog}
            onSubmit={(data) => handleChange('usAPHISForm', data)}
          />
        )
      case 'naftaForm':
        return (
          <NaftaFormSection
            naftaForm={naftaForm}
            onClose={handleCloseDialog}
            onSubmit={(data) => handleChange('naftaForm', data)}
          />
        )
      default:
        return null
    }
  }, [dialog.key])

  function getDialogTitle(key) {
    switch (key) {
      case 'usAPHISForm':
        return 'USDA APHIS VS form 17 - 29'
      case 'naftaForm':
        return 'USMCA-CUSMA form'
      case 'fdabtaform':
        return 'FDA/BTA prior notice form & instructions'
      case 'bolForm':
        return 'Bill of Lading form'
      case 'tscaform':
        return 'Toxic Substance Control Act (TSCA) Certification'
      case 'packingList':
        return 'Packing list form'
      case 'laceyAct':
        return 'Lacey Act form'
      default:
        return ''
    }
  }

  return (
    <>
      <CngSection title='Additional Form Information'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={getDialogTitle('usAPHISForm')}
              data={usAPHISForm}
              onManage={() => setDialog({ key: 'usAPHISForm', open: true })}
              onDownload={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_USDA_APHIS";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate + ".pdf";

                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }

                function onComplete() {

                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST', NbciUsInvoiceApiUrls.PRINT_USAPHIS, getValues(), onSuccess, onError, onComplete, config, null);

              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={getDialogTitle('naftaForm')}
              data={naftaForm}
              onManage={() => setDialog({ key: 'naftaForm', open: true })}
              onDownload={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_USMCA_CUSMA_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate + ".pdf";

                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }

                function onComplete() {

                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST', NbciUsInvoiceApiUrls.PRINT_USMCACUSMA, getValues(), onSuccess, onError, onComplete, config, null);

              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={getDialogTitle('fdabtaform')}
              data={fdabtaform}
              onManage={() => setDialog({ key: 'fdabtaform', open: true })}
              onDownload={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_FDA_BTA_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate + ".pdf";

                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }

                function onComplete() {

                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST', NbciUsInvoiceApiUrls.PRINT_FDA_BTA, getValues(), onSuccess, onError, onComplete, config, null);

              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={getDialogTitle('bolForm')}
              data={bolForm}
              onManage={() => setDialog({ key: 'bolForm', open: true })}
              onDownload={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_BOL_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate + ".pdf";

                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }

                function onComplete() {

                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST', NbciUsInvoiceApiUrls.PRINT_BOL, getValues(), onSuccess, onError, onComplete, config, null);

              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={getDialogTitle('tscaform')}
              data={tscaform}
              onManage={() => setDialog({ key: 'tscaform', open: true })}
              onDownload={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_TSCA_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate + ".pdf";

                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }

                function onComplete() {

                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST', NbciUsInvoiceApiUrls.PRINT_TSCA, getValues(), onSuccess, onError, onComplete, config, null);

              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={getDialogTitle('packingList')}
              data={packingList}
              onManage={() => setDialog({ key: 'packingList', open: true })}
              onDownload={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_PACKING_LIST_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate + ".pdf";

                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }

                function onComplete() {

                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST', NbciUsInvoiceApiUrls.PRINT_PL, getValues(), onSuccess, onError, onComplete, config, null);

              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={getDialogTitle('laceyAct')}
              data={laceyAct}
              onManage={() => setDialog({ key: 'laceyAct', open: true })}
              onDownload={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_LACEY_ACT_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate + ".pdf";

                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }

                function onComplete() {

                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST', NbciUsInvoiceApiUrls.PRINT_LACEYACT, getValues(), onSuccess, onError, onComplete, config, null);

              }}
            />
          </Grid>
        </Grid>
      </CngSection>
      <CngDialog
        customDialogContent={<Box padding={2}>{renderForm()}</Box>}
        dialogTitle={`Manage ${getDialogTitle(dialog.key)}`}
        fullWidth
        maxWidth='md'
        onClose={handleCloseDialog}
        open={dialog.open && dialog.key !== null}
        shouldShowCloseButton
      />
    </>
  )
}

export default AdditionalFormInformationSection
