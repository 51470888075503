import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import UsDeclarationsKeys from '../../../constants/locale/key/UsDeclarations'
import pathMap from '../../../paths/pathMap'
import UsDeclarationsApiUrls from '../../../apiUrls/UsDeclarationsApiUrls'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import CngField from '../../../components/cngcomponents/CngField'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Grid
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CngSection from '../../../components/cngcomponents/CngSection'

const {
  button: { CngButton }
} = components

function ViewPage(props) {
  const { history, showNotification } = props

  const { id } = useParams()
  const [data, setData] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { deleteRecord, fetchRecord } = useServices()
  const { translate } = useTranslation(Namespace.US_DECLARATIONS)

  useEffect(() => {
    fetchRecord.execute(UsDeclarationsApiUrls.GET, id, (res) => {
      const data = res.content[0]

      if (data === undefined) {
        showNotification('error', 'Data not found.')
        history.push(pathMap.US_DECLARATIONS_LIST_VIEW)
      } else {
        setData(data)
      }
    })
  }, [])

  if (!data) {
    return (
      <Grid container justify='center'>
        <Grid item xs='auto'>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const usDeclarations = translate(
      Namespace.US_DECLARATIONS,
      UsDeclarationsKeys.TITLE
    )
    const declarationId = translate(
      Namespace.US_DECLARATIONS,
      UsDeclarationsKeys.DECLARATION_ID
    )
    const declarationDescription = translate(
      Namespace.US_DECLARATIONS,
      UsDeclarationsKeys.DECLARATION_DESCRIPTION
    )

    return { usDeclarations, declarationId, declarationDescription }
  }

  function handleDeleteDeclaration() {
    deleteRecord.execute(UsDeclarationsApiUrls.DELETE, data, () => {
      showNotification('success', 'Delete succeeded')
      history.push(pathMap.US_DECLARATIONS_LIST_VIEW)
    })
  }

  return (
    <>
      <Card>
        <CardContent>
          <CngSection title={translatedTextsObject.usDeclarations}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CngField label={translatedTextsObject.declarationId}>
                  {data.declarationId}
                </CngField>
              </Grid>
              <Grid item xs={12}>
                <CngField label={translatedTextsObject.declarationDescription}>
                  {data.declarationDescription}
                </CngField>
              </Grid>
            </Grid>
          </CngSection>
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton
            color='secondary'
            onClick={() => history.push(pathMap.US_DECLARATIONS_LIST_VIEW)}
          >
            Back to US declarations list
          </CngButton>
          <Box display='flex' style={{ gap: 16 }}>
            <CngButton
              color='secondary'
              onClick={() => setConfirmDialog(true)}
              startIcon={<FontAwesomeIcon icon={['fal', 'trash']} size='sm' />}
            >
              Delete
            </CngButton>
            <CngButton
              startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='sm' />}
              onClick={() => history.push(`${pathMap.US_DECLARATIONS}/edit/${id}`)}
            >
              Edit
            </CngButton>
          </Box>
        </CardActions>
      </Card>
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={handleDeleteDeclaration}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default ViewPage
