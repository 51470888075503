import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './CargoSummaryFormProperties'
import CargoSummaryFieldsPlaceholder from './CargoSummaryFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function CargoSummarySection(props) {
  const { cargoSummary = [], setCargoSummaryDirty } = props

  const generatedIndex = useRef(0)
  const { getValues, setValue } = useFormContext()

  function handleAddCargoSummary(data) {
    if (undefined === getValues('ciForm.0.nbciusinvoiceCargoSummary')) {
      let cargoSum = [{ ...data, _id: 1 }]
      setValue('ciForm.0.nbciusinvoiceCargoSummary', cargoSum)
    } else {
      const cargoSummary = [...getValues('ciForm.0.nbciusinvoiceCargoSummary'), data].map(
        (material, index) => ({ ...material, _id: index + 1 })
      )
      setValue('ciForm.0.nbciusinvoiceCargoSummary', cargoSummary)
    }
    setCargoSummaryDirty(true)
  }
  
  function handleDeleteCargoSummary(data) {
    const clonedCargoSummary = [...getValues('ciForm.0.nbciusinvoiceCargoSummary')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )
    const cargoSummary = clonedCargoSummary.map(
      (material, index) => ({ ...material, _id: index + 1 })
    )
    setValue('ciForm.0.nbciusinvoiceCargoSummary', cargoSummary)
    setCargoSummaryDirty(true)
  }

  function handleEditCargoSummary(index, data) {
    const cargoSummary = [...getValues('ciForm.0.nbciusinvoiceCargoSummary')]

    cargoSummary[index] = data

    setValue('ciForm.0.nbciusinvoiceCargoSummary', cargoSummary)
    setCargoSummaryDirty(true)
  }

  return (
    <Grid container spacing={1}>
      {_.isEmpty(cargoSummary) ? (
        <Grid item xs={12}>
          <CargoSummaryFieldsPlaceholder onAdd={handleAddCargoSummary} />
        </Grid>
      ) : (
        cargoSummary.map((summary, index) => (
          <React.Fragment key={Math.random()}>
            <Grid item xs={12}>
              <CargoSummaryEntry
                cargoSummary={summary}
                onAdd={handleAddCargoSummary}
                onDelete={handleDeleteCargoSummary}
                onEdit={(data) => handleEditCargoSummary(index, data)}
              />
            </Grid>
            {index + 1 !== cargoSummary.length && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        ))
      )}
    </Grid>
  )
}

function CargoSummaryEntry(props) {
  const { cargoSummary, onAdd, onDelete, onEdit } = props

  const methods = useForm({ defaultValues: cargoSummary })
  const {
    formState: { isDirty }
  } = methods

  function handleEditCargoSummary(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12} sm>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12} sm='auto'>
          {isDirty ? (
            <Grid container spacing={1}>
              <Grid item xs='auto'>
                <Grid container spacing={2}>
                  <Grid item xs='auto'>
                    <CngButton
                      color='secondary'
                      onClick={() => methods.reset()}
                      size='medium'
                    >
                      Reset
                    </CngButton>
                  </Grid>
                  <Grid item xs='auto'>
                    <CngButton
                      color='primary'
                      onClick={methods.handleSubmit(handleEditCargoSummary)}
                      size='medium'
                    >
                      Save changes
                    </CngButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Add'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'plus']}
                      onClick={() => onAdd(initialValues)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Clone'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'copy']}
                      onClick={() => onAdd({ ...cargoSummary, id: undefined })}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Delete'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'trash']}
                      onClick={() => onDelete(cargoSummary)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            )}
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default CargoSummarySection
