import makeValidationSchema from './CargoSummaryMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../constants/locale/key/NbciUsInvoice'
import { Grid } from '@material-ui/core'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  reference: '',
  referenceNumber: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const reference = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceCargoSummary.REFERENCE
    )
    const referenceNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceCargoSummary.REFERENCE_NUMBER
    )

    return { reference, referenceNumber }
  }
  
  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.reference}>
        <CngCodeMasterAutocompleteField
          name='reference'
          label={translatedTextsObject.reference}
          disabled={disabled}
          codeType='NBCI_US_INV_REFERENCE'
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.referenceNumber}>
        <CngTextField
          name='referenceNumber'
          label={translatedTextsObject.referenceNumber}
          disabled={disabled}
          size='small'
          inputProps={{ maxLength: 50 }}
        />
      </CngGridItem>
    </Grid>
  )
}

const NBCIUSInvoiceCargoSummaryFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default NBCIUSInvoiceCargoSummaryFormProperties
