import React,  {useEffect} from 'react'
import Namespace from '../../../constants/locale/Namespace'
import UsDeclarationsKeys from '../../../constants/locale/key/UsDeclarations'
import { Grid, Typography } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useFormContext,useWatch } from 'react-hook-form'
import CngSection from '../../../components/cngcomponents/CngSection'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  declarationId: '',
  declarationDescription: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldDisableMap, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.US_DECLARATIONS)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue } = useFormContext()

  function makeTranslatedTextsObject() {
    const usDeclarations = translate(
      Namespace.US_DECLARATIONS,
      UsDeclarationsKeys.TITLE
    )
    const declarationId = translate(
      Namespace.US_DECLARATIONS,
      UsDeclarationsKeys.DECLARATION_ID
    )
    const declarationDescription = translate(
      Namespace.US_DECLARATIONS,
      UsDeclarationsKeys.DECLARATION_DESCRIPTION
    )

    return { usDeclarations, declarationId, declarationDescription }
  }

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  return (
    <CngSection
      title={translatedTextsObject.usDeclarations}
      subheader={
        <Typography color='textSecondary' variant='caption'>
          <Typography variant='inherit' color='error'>*</Typography>
          Mandatory fields
        </Typography>
      }
    >
      <Grid container spacing={1}>
        <CngGridItem xs={12} shouldHide={shouldHideMap.declarationId}>
          <CngTextField
            name='declarationId'
            inputProps={{ maxLength: 11 }}
            onChange={(e) => setValue('declarationId', e.target.value.toUpperCase())}
            label={translatedTextsObject.declarationId}
            isRequired
            disabled={shouldDisableMap?.declarationId}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} shouldHide={shouldHideMap.declarationDescription}>
          <CngTextField
            name='declarationDescription'
            inputProps={{ maxLength: 200 }}
            onChange={(e) => setValue('declarationDescription', e.target.value.toUpperCase().replace(/(\r\n|\r|\n)+/g, ' '))}
            label={translatedTextsObject.declarationDescription}
            isRequired
            disabled={disabled}
            size='small'
            multiline
            rows={3}
          />
        </CngGridItem>
      </Grid>
    </CngSection>
  )
}

function toClientDataFormat(serverData) {
  return serverData
}

function toServerDataFormat(localData) {
  return localData
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
