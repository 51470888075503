import React, {useEffect , useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import NbciUsInvoiceApiUrls from 'src/apiUrls/NbciUsInvoiceApiUrls'
import { format } from 'date-fns'
import Table from '../../../components/aciacehighway/Table'

const {
  button: { CngButton },
  CngDialog,
} = components




function PrintFlagLineIteamDialogAll(props) {
  const {
    openDialog,
    onClose  } = props

    // function handleDisableDot(manifest){
    //   let DOTExists = false;
    //   console.log("handleDisableDot length");
    //   if (manifest) {
    //     console.log("handleDisableDot inside 1"+JSON.stringify(manifest));
    //     if (manifest.invoiceDetails) {
    //         manifest.invoiceDetails.forEach((e) => {
    //           console.log("handleDisableDot inside 2"+JSON.stringify(e));
    //         if (e.nbciusinvoiceDetailsDOTForm) {
    //           DOTExists=true;
    //           console.log("handleDisableDot"+DOTExists);
    //         }
    //       })
    //     }
    //     isDOFormSet(DOTExists);
    // }
    // }

// const [DOFormSet, isDOFormSet] = useState(true);

// useEffect(() => {
//   let DOTExists = false;
//   console.log("Anjali DOT"+DOTExists);
//   if (openDialog.manifest && openDialog.manifest.length > 0) {
//     if (openDialog.manifest.invoiceDetails && openDialog.manifest.invoiceDetails.length>0) {
//       openDialog.manifest.invoiceDetails.forEach((e) => {
//         if (e.nbciusinvoiceDetailsDOTForm && e.nbciusinvoiceDetailsDOTForm.length>0) {
//           DOTExists=true;
//           console.log(DOTExists);
//         }
//       })
//     }
//     isDOFormSet(DOTExists);
//   }

// }, [])

  const { securedSendRequest } = useServices();
  console.log("Inside printFalgDialog: " +JSON.stringify(openDialog.manifest) );

  let data = openDialog.manifest
  let dotFormCount = 0;
  let fdaFormCount = 0;
  let fccFormCount = 0;
  let epa35201FormCount = 0;
  let epa352021FormCount = 0;

  if(data && data.invoiceDetails){
    for (var invoiceDetail of data.invoiceDetails) {
      dotFormCount += invoiceDetail.nbciusinvoiceDetailsDOTForm.length;
      fdaFormCount += invoiceDetail.nbciusinvoiceDetailsFDAForm.length;
      fccFormCount += invoiceDetail.nbciusinvoiceDetailsFCCForm.length;
      epa35201FormCount += invoiceDetail.nbciusinvoiceDetailsEPA35201Form.length;
      epa352021FormCount += invoiceDetail.nbciusinvoiceDetailsEPA352021Form.length;
    }
  }
  return (
    <>
    <CngDialog
      customDialogContent={
        <Paper>
          <Box m={2} mt={1}>
            <Box mt={0.5}>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
             // disabled ={handleDisableDot(openDialog.manifest)}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_DOT_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".zip";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_DOT_ALL, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              disabled={dotFormCount==0}
              >
                DOT
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_FCC_740_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".zip";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_FCC_ALL, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              disabled={fccFormCount==0}
              >
                FCC740
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_FDA_2877_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".zip";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_FDA_ALL, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              disabled={fdaFormCount==0}
              >
                FDA_2877
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_EPA_3520_1_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".zip";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_EPA_1_ALL, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              disabled={epa35201FormCount==0}
              >EPA_3520_1
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_EPA_3520_21_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".zip";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_EPA_21_ALL, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              disabled={epa352021FormCount==0}
              >EPA_3520_21
              </CngButton>
            </Box>
           </Box>
         </Paper>
      }
      dialogTitle={
        <>
          <b>Print Forms</b>
          <CloseDialogIconButton onClick={onClose} />
        </>
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={openDialog.open}
    />
    </>
  )
}

export default PrintFlagLineIteamDialogAll