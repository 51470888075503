import React, { useState, useEffect } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import { makeStyles } from '@material-ui/core'
import NbciUsInvoiceApiUrls from 'src/apiUrls/NbciUsInvoiceApiUrls'
import { format } from 'date-fns'
import  NaCodeMaintenanceApiURLs  from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import { object } from 'check-types'

const {
  button: { CngButton },
  CngDialog,
} = components

const {
  NotificationType,
  filter: {EQUAL}
} = constants

function PrintFlagDialog(props) {
  const {
    openDialog,
    onClose  } = props

  const { fetchRecords, securedSendRequest } = useServices();
  console.log("Inside printFalgDialog: " +JSON.stringify(openDialog.manifest) );

  const [TSCAButtonOption, setTSCAButtonOption] = useState()
  useEffect(() => {
    getTSCAButtonOptions();
  }, [])

  const getTSCAButtonOptions = () => {
    const onSuccess = (response) => {
        const options = response.content.map((status) => {
          return status.code
        })
        setTSCAButtonOption(options);
    }

    fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET_NOT_IN_NA,
        {
          customData: { codeMType: constants.CodeMaintenanceType.CODE_MASTER },
          filters: [
            {
              field: 'active',
              operator: 'equal',
              value: 'true'
            },
            {
              field: 'codeType',
              operator: 'equal',
              value: 'NBCI_SHOW_TSCA_BTN'
            }
          ]
        },
        onSuccess,
        () => {
            
        }
    )
  }

  return (
    <CngDialog
      customDialogContent={
        <Paper>
          <Box m={2} mt={1}>
            <Box mt={0.5}>
            
            <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.manifest && openDialog.manifest.bolForm && openDialog.manifest.bolForm.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_BOL_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_BOL, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              >BOL
              </CngButton>
              
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.manifest && openDialog.manifest.laceyAct && openDialog.manifest.laceyAct.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_LACEY_ACT_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_LACEYACT, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              >LACEYACT
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.manifest && openDialog.manifest.naftaForm && openDialog.manifest.naftaForm.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_USMCA_CUSMA_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_USMCACUSMA, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              >USMCACUSMA
              </CngButton>
              
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.manifest && openDialog.manifest.packingList && openDialog.manifest.packingList.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_PACKING_LIST_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_PL, openDialog.manifest, onSuccess, onError, onComplete,config,null);  

               }}
              >PACKING LIST
              </CngButton>
              <br></br>
              <br></br>
              
              {TSCAButtonOption == "Y" && 
              <>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.manifest && openDialog.manifest.tscaform && openDialog.manifest.tscaform.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_TSCA_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_TSCA, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              >TSCA
              </CngButton>
              <br></br>
              <br></br>
              </>
              }

              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.manifest && openDialog.manifest.fdabtaform && openDialog.manifest.fdabtaform.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_FDA_BTA_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_FDA_BTA, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              >FDA-BTA
              </CngButton>

              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.manifest && openDialog.manifest.usAPHISForm && openDialog.manifest.usAPHISForm.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_USDA_APHIS";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_USAPHIS, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              >USDA APHIS VS 17-29
              </CngButton>

              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "CI_USINV_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NbciUsInvoiceApiUrls.PRINT_CI_FORM, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              >CI FORM
              </CngButton>

            </Box>
           </Box>
         </Paper>
      }
      dialogTitle={
        <>
          <b>Print Forms</b>
          <CloseDialogIconButton onClick={onClose} />
        </>
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={openDialog.open}
    />
  )
}

export default PrintFlagDialog